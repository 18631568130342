import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const events = () => {
  const data = useStaticQuery(graphql`
    query EventsSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Events" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="pt-5 mt-5 bg-light-blue pb-4">
        <div className="container-1 pt-3 pb-5">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center pt-4">
              <h1
                className="text-white mobile-margin-reset"
                style={{ fontWeight: 'bold' }}
              >
                Apache Kafka - DataOps Events
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section id="summary" className="pt-5 pb-5">
        <div className="container-1 pb-4">
          <div className="d-flex flex-wrap">
            <div className="col-md-12">
              <div id="events">
                <div className="row pb-5">
                  <div className="col-md-12 text-center">
                    <h2 style={{ fontSize: '38px' }}>2020</h2>
                    <StaticImage
                      className="img-fluid mx-auto d-block mt-5"
                      style={{ width: '90%', maxWidth: '700px' }}
                      src="../../resources/images/homepage/events/kafka-summit-2020-dataops-lenses.png"
                      placeholder="tracedSVG"
                      alt="Apache Kafka realtime DataOps and GitOps from Lenses.io"
                    />
                    <a
                      href="https://www.confluent.io/resources/kafka-summit-2020/dataops-automation-for-a-kafka-streaming-platform/"
                      target="_blank"
                      className="link-text"
                      rel="noopener noreferrer"
                    >
                      View Event →
                    </a>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-12 text-center">
                    <h2 style={{ fontSize: '38px' }}>2019</h2>
                  </div>
                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Big Data LDN, <br /> London UK
                      </h2>
                      <p>13-14 Nov 2019</p>
                      <a
                        href="https://bigdataldn.com"
                        target="_blank"
                        className="link-text"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Kafka Summit, <br /> San Francisco
                      </h2>
                      <p>30-1st Oct 2019</p>
                      <br />
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        17th HDMS, <br /> Athens
                      </h2>
                      <p>8-9 Jul 2019</p>
                      <a
                        href="https://hdms19.athenarc.gr"
                        className="link-text"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Influx Days, <br /> London
                      </h2>
                      <p>13-14 Jun 2019</p>
                      <a
                        href="https://influxdays.com/london-2019/"
                        className="link-text"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Kafka Summit, <br /> London
                      </h2>
                      <p>14-15 May 2019</p>
                      <a
                        href="https://kafka-summit.org/events/kafka-summit-london-2019/"
                        className="link-text"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View Event →
                      </a>
                      {/* <!-- <a href="//kafka-summit.org/events/kafka-summit-london-2019/" target="_blank">Go to Conference</a> --> */}
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        GreeceJS, <br /> Athens
                      </h2>
                      <p>7 May 2019</p>
                      <a
                        href="https://www.meetup.com/GreeceJS/events/260624869/"
                        target="_blank"
                        className="link-text"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Redis Conf, <br /> San Francisco
                      </h2>
                      <p>7 May 2019</p>
                      <a href="/blog/2019/04/sql-redis/" className="link-text">
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Kafka Meetup, <br /> New York
                      </h2>
                      <p>17 Jan 2019</p>
                      <a
                        href="https://www.meetup.com/Apache-Kafka-NYC/events/256691965/"
                        target="_blank"
                        className="link-text"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-12">
                    <h2 className="text-center">2018</h2>
                  </div>
                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Kafka Summit, <br /> San Francisco
                      </h2>
                      <p>16-17 Oct 2018</p>
                      <a
                        href="https://kafka-summit.org/events/kafka-summit-san-francisco-2018/"
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Strata Data, <br /> London
                      </h2>
                      <p>21-24 May 2018</p>
                      <a
                        href="https://conferences.oreilly.com/strata/strata-eu"
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Kafka Summit, <br /> London
                      </h2>
                      <p>23-24 Apr 2018</p>
                      <a
                        href="https://kafka-summit.org/kafka-summit-london-2018/sponsors/"
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Chief Data Officer, <br /> London
                      </h2>
                      <p>13-14 Mar 2018</p>
                      <a
                        href="https://chiefdataofficerexchange.iqpc.co.uk"
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Strata Data, <br /> San Jose
                      </h2>
                      <p>5-6 Mar 2018</p>
                      <a
                        href="https://conferences.oreilly.com/strata/strata-ca"
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Kafka Meetup, <br /> London
                      </h2>
                      <p>21 Feb 2018</p>
                      <a
                        href="https://www.meetup.com/Apache-Kafka-London/events/247649422/"
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>
                </div>

                {/* 2017 */}
                <div className="row mt-5">
                  <div className="col-md-12">
                    <h2 className="text-center">2017</h2>
                  </div>
                  <div className="col-md-4 mt-4">
                    <div className="p-4 events-box-shadow">
                      <h2 className="paragraph-title2">
                        Big Data Meetup, <br /> Athens
                      </h2>
                      <p>19 Sep 2017</p>
                      <a
                        href="/blog/2017/09/athens-big-data-meetup/"
                        className="link-text"
                      >
                        View Event →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mobile-margin-bottom mobile-margin-top my-5">
        <div className="container-1 bg-dark rounded-1 p-5">
          <div className="d-flex flex-wrap">
            <div className="col-md-12 col-12 text-center roll-button">
              <p className="mb-5 paragraph-title text-white">
                Ready to get started with Lenses?
              </p>
              <a className="bg-white primary-text" href="/start/">
                <span data-hover="Try now for free"> Try now for free</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default events
